.badge{
 padding: 0.3em 0.75em !important;
}

.badge-incompleta{
 background-color: #d9d9d9; /* */
 /*opacity: 0.65;*/
}

.badge-cancelada{
 background-color: black; /* var(--gray) */
 color:rgba(255, 255, 255, 0.8 ) !important
}

.badge-danger{
 background-color: var(--red);
 color:rgba(255, 255, 255, 0.8 ) !important
}

.badge-acolhida{
 background-color: var(--info);
 color:var(--white) !important
}

.badge-aguardando-atendimento{
 background-color: #fffc03;  /* var(--warning) */
 color:black !important
}

.badge-em-atendimento{
 background-color: #a3c1fb;  /* var(--teal) */
 color: var(--white) !important
}

.badge-atendimento-concluido{
 background-color: #1753c3;  /* var(--success) */
 color: var(--white) !important
}

.badge-atendimento-com-pendencia{
 background-color: #ca0008;  /* var(--gray-dark) */
 color: var(--white) !important
}

.badge-encerrada{
 background-color: #67a94d;  /* var(--dark) */
 color: var(--white) !important
}

.badge-aguardando-acolhimento{
  background-color: #FA8600;  /* new */
  color:rgba(255, 255, 255, 0.8 ) !important
 }

.badge-aguardando-novo-atendente{
  background-color: #9503f6;  /* new */
  color:rgba(255, 255, 255, 0.8 ) !important
 }

.badge-em-andamento{
  background-color: #fae35f;  /* var(--success) */
  color: var(--white) !important
 }

.badge-realizada{
  background-color: #1753c3;  /* var(--success) */
  color: var(--white) !important
 }
 