.reactMarkdown img, .reactMarkdown video{
 max-width: 100%;
 margin: 8px 0 16px;
}

.reactMarkdown ol, .reactMarkdown ul{
 padding-left: 0px;
}

.reactMarkdown ol li, .reactMarkdown ul li{
 margin-left: 14px;
}

.reactMarkdown li img{
 margin-left: -12px;
}

.reactMarkdown li video{
 margin-left: -10px;
}

.reactMarkdown a img{
 display: block;
}