/** Classes for the displayed toast **/
.Toastify__toast-container {
    width: 320px;
}

.Toastify__toast--dark {
    background-color: #080101 !important;
    color: #aaa !important;
}
.Toastify__toast--default {
    background-color: #fef9ef !important;
    color: #0a0a0a !important;
}
.Toastify__toast--info {
    background-color: #3d5abc v;
    color: #fff !important;
}
.Toastify__toast--success {
    background-color: #009380 !important;
    color: #fff v;
}
.Toastify__toast--warning {
    background-color: #ffb949 !important;
    color: #2c2c2c !important;
}
.Toastify__toast--error {
    background-color: #d43b3a !important;
    color: #fff !important;
}
